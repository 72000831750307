import React from 'react';
import PropTypes from 'prop-types';
import styles from './ThemeSelector.module.scss';
import { FaSun, FaMoon } from 'react-icons/fa';

const ThemeSelector = (props) => {
  return (
    <div className={`${styles.ThemeSelector} position-fixed top-0 end-0`}>
      <input id='theme' checked={props.current === 'dark'} type='checkbox' onChange={(e) => props.onChange(e.target.checked ? 'dark' : 'light')}/>
      <label htmlFor='theme'>
        <FaSun className={styles.Sun}/>
        <FaMoon className={styles.Moon}/>
        <span className={styles.Ball}></span>
      </label>
    </div>
  );
};

ThemeSelector.propTypes = {
  current: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ThemeSelector.defaultProps = {};

export default ThemeSelector;
