import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import ClearUrl from '../ClearUrl/ClearUrl';
import { BsFiles } from 'react-icons/bs';
import styles from './UrlList.module.scss';
import ThemeContext from '../../contexts/ThemeContext';

const UrlList = (props) => {

  const theme = useContext(ThemeContext);

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
  };

  return (
    props.urls.length > 0 && 
      <Table striped bordered hover variant={theme}>
        <thead>
          <tr>
            <th style={{width: '50%'}}>Short URL</th>
            <th style={{width: '50%'}}>Long URL</th>
          </tr>
        </thead>
        <tbody>
          {props.urls.map((url, id) => (
            <tr key={id}>
              <td>
                <ClearUrl url={url.short}/>
                <BsFiles onClick={() => copyToClipboard(url.short)} className={`${styles.CopyToClipboard} mx-1`}/>
              </td>
              <td>
                <ClearUrl url={url.long}/>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  );
};

UrlList.propTypes = {
  urls: PropTypes.array.isRequired
};

UrlList.defaultProps = {};

export default UrlList;
