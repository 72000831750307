import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { BsGlobe2 } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

const ShortenerForm = (props) => {

  const [url, setUrl] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.onSubmit(url);

    setUrl('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup size='lg' className='mb-3'>
        <InputGroup.Text><BsGlobe2/></InputGroup.Text>
        <Form.Control value={url} onChange={e => setUrl(e.target.value)} placeholder='https://...' required/>
        <Button type='submit'>Shorten URL</Button>
      </InputGroup>
    </Form>
  );
};

ShortenerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

ShortenerForm.defaultProps = {};

export default ShortenerForm;
