import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ThemeContext from '../../contexts/ThemeContext';

const ClearUrl = ({ url }) => {
  const theme = useContext(ThemeContext);

  return (
    <a href={url} className={theme === 'dark' ? 'link-light' : 'link-dark'}>
      {url}
    </a>
  );
};

ClearUrl.propTypes = {
  url: PropTypes.string.isRequired
};

ClearUrl.defaultProps = {};

export default ClearUrl;
