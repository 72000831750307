import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import ShortenerForm from '../../components/ShortenerForm/ShortenerForm';
import './App.scss';
import UrlList from '../../components/UrlList/UrlList';
import Col from 'react-bootstrap/esm/Col';
import ThemeSelector from '../../components/ThemeSelector/ThemeSelector';
import { ThemeProvider } from '../../contexts/ThemeContext';

const App = () => {

  const [theme, setTheme] = useState('light');
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');

    if(!storedTheme) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark');
      }
    } else {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    if(theme === 'dark') {
      document.body.classList.add('theme-dark');
    } else {
      document.body.classList.remove('theme-dark');
    }
  }, [theme]);

  const handleThemeChange = (newTheme) => {
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  const handleSubmit = async (url) => {
    const data = await fetch(`${window.location.protocol}//${window.location.host}/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({url: url})
    });

    if(data && data.status) {
      const id = await data.json();

      const shortUrl = `${window.location.protocol}//${window.location.host}/${id}`;

      setUrls(prev => (
        [{key: id, long: url, short: shortUrl}, ...prev]
      ));
    }
  };

  return (
    <ThemeProvider value={theme}>
      <ThemeSelector current={theme} onChange={handleThemeChange}/>
      <Container>
        <Row className='justify-content-md-center'>
          <Col lg={7}>
            <div className='mt-5 text-center'>
              <img className='d-block mx-auto mb-4' src={`${process.env.PUBLIC_URL}/favicon.png`} alt='' width='72' height='72' />
              <h1 className='fw-bold'>Simple URL Shortener</h1>
              <p className='lead'>
                This URL shortener lets you create short URLs while maintaining your privacy!<br/>
                No data is collected except urls you want to shorten 😉
              </p>
            </div>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col lg={7}>
            <div className='mt-5'>
              <ShortenerForm onSubmit={handleSubmit}/>
            </div>
          </Col>
        </Row>
        <Row className='mt-5'>
          <UrlList urls={urls}/>
        </Row>
      </Container>
    </ThemeProvider>
  );
};

export default App;
